import './Entretenimiento.css';

// import banner from '../../images/banner.png';

function Entretenimiento() {
  return (
    <div id='entretenimiento'>
      <h1 className='txt-center'>Entretenimiento</h1>
    </div>
  );
}

export default Entretenimiento;
