// import { NavLink } from "react-router-dom";

import './BNC01.css';

import mapa from '../../../images/movilidad/mapa-banco-ruta01.jpg';

function Ruta() {
  return (
    <div className='full'>
      <img src={mapa} width="" height="" alt='' className='full cnt map' />
    </div>
  );
}

export default Ruta;
