import './NacionDeportes.css';

function NacionDeportes() {
  return (
    <div id='nd' className='full'>
      <iframe src='https://sports.alf.me/' className='full' title='Deepar' />
    </div>
  );
}

export default NacionDeportes;
