import './Juegos.css';

function Juegos() {
  return (
    <div id='juegos' className='full'>
      <h1 className='txt-center'>Juegos</h1>
    </div>
  );
}

export default Juegos;
